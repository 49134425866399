// routing
import Routes from 'routes';
import React from 'react';
// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import { Alert, Button, CssBaseline, Slide, StyledEngineProvider } from '@mui/material';
import { IconX } from '@tabler/icons';
import Snackbar from 'ui-component/extended/Snackbar';
import { SnackbarProvider } from 'notistack';
import ThemeCustomization from 'themes';
import { SnackbarUtilsConfigurator } from 'utils/SnackbarUtils';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    const handleSnackbarClose = (key) => (
        <Button onClick={onClickDismiss(key)}>
            <IconX color="white" />
        </Button>
    );

    return (
        <ThemeCustomization>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <SnackbarProvider
                            maxSnack={3}
                            ref={notistackRef}
                            action={handleSnackbarClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            TransitionComponent={Slide}
                        >
                            <SnackbarUtilsConfigurator />
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </SnackbarProvider>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </ThemeCustomization>
    );
};

export default App;
